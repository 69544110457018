import axios from 'axios'

export default {
    async loginGuest(token) {
        try {
            const response = await axios.put(`/guest/${token}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async content(path) {
        try {
            const response = await axios.post('/filemanager/content',path);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async tree(path) {
        try {
            const response = await axios.post('/filemanager/tree',path);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async newFolder(data) {
        try {
            const response = await axios.post('/filemanager/createdirectory',data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async shareContent(items) {
        try {
            const response = await axios.post('/filemanager/sharecontent',items);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async share(data) {
        try {
            const response = await axios.post('/filemanager/share',data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async shareUrlGuest(data) {
        try {
            const response = await axios.post('/filemanager/urlguest',data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async rename(data) {
        try {
            const response = await axios.post('/filemanager/rename',data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async paste(data) {
        try {
            const response = await axios.post('/filemanager/paste',data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.post('/filemanager/delete',data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async upload(data,config) {
        try {
            const response = await axios.post('/filemanager/upload',data,config);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async makeZip(data) {
        try {
            const response = await axios.post('/filemanager/makezip',data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getActiveUrls() {
        try {
            const response = await axios.get('/filemanager/activeurls');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async disableUrl(data) {
        try {
            const response = await axios.post(`/filemanager/disableurl`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getUsersWithNotifications(data) {
        try {
            const response = await axios.post('/filemanager/userswithnotifications', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async uploadNotificationsOfUser(data) {
        try {
            const response = await axios.post(`/filemanager/uploadnotificationsofuser`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async disableUploadNotification(data) {
        try {
            const response = await axios.post(`/filemanager/disableuploadnotification`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async editUrl(data) {
        try {
            const response = await axios.post(`/filemanager/editurl`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getSharedClients(revoked) {
        try {
            const response = await axios.post('/filemanager/sharedclients',{revoked:revoked});
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getActiveSharedClients() {
        try {
            const response = await axios.get('/filemanager/activesharedclients');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async permissionsSharedClient(data) {
        try {
            const response = await axios.post(`/filemanager/permissionssharedclient`, {id:data.id});
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async permissionsRevokedSharedClient(data) {
        try {
            const response = await axios.post(`/filemanager/permissionsrevokedsharedclient`, {id:data.id});
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async deletePermission(data) {
        try {
            const response = await axios.post(`/filemanager/deletepermission`, {id:data.id});
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async loginGuestPassword(data) {
        try {
            const response = await axios.post(`/loginguest`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async searchBox(data) {
        try {
            const response = await axios.post(`/filemanager/searchbox`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async show(data) {
        try {
            const response = await axios.post(`/filemanager/show`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getUrl(data) {
        try {
            const response = await axios.post(`/filemanager/geturl`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },

}
